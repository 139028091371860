import React, { useEffect, useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import supabase from '../supabaseClient';
import AnimatedCounter from './AnimatedCounter'; // Asegúrate de importar AnimatedCounter si no lo has hecho aún

const TotalUserVotes = ({ setTotalVotes }) => {
  const [localTotalVotes, setLocalTotalVotes] = useState(0);
  const address = useAddress();

  useEffect(() => {
    const fetchTotalVotes = async () => {
      if (!address) {
        return;
      }
  
      try {
        const { count, error } = await supabase
          .from('user_votes')
          .select('*', { count: 'exact', head: true })
          .eq('user_address', address); // Usa 'address' directamente
  
        if (error) {
          throw error;
        }
  
        setLocalTotalVotes(count);
        setTotalVotes(count); // Actualiza el estado en el componente padre
  
        // Guardar totalVotes en user_stats
        await saveTotalVotesToUserStats(address, count); // Usa 'address' directamente
      } catch (error) {
        console.error('Error fetching or saving total votes:', error.message);
      }
    };

    fetchTotalVotes();
  }, [address]);

  const saveTotalVotesToUserStats = async (userAddress, totalVotes) => {
    try {
      const { error } = await supabase
        .from('user_stats')
        .upsert({
          wallet_address: userAddress,
          votes_cast: totalVotes,
          updated_at: new Date().toISOString(),
        }, { onConflict: ['wallet_address'] });

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Error saving total votes to user_stats:', error.message);
    }
  };

  return (
    <div className="text-5xl font-bold total-votes">
      <AnimatedCounter target={localTotalVotes} />
    </div>
  );
};

export default TotalUserVotes;
