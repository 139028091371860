import React, { useState } from 'react';
import socials from './socials.png';
import ModalSocials from './ModalSocials'; // Importa el nuevo modal

const Footer = ({ showContractInfo, setBlurBackground }) => {
  const [showSocialsModal, setShowSocialsModal] = useState(false); // Estado para el nuevo modal
  /*const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    const contractAddress = '0x8Aeb5ec54c65C2b44482098E1e02A8E712678b91';
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopySuccess('Contract copied');
      setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
    });
  };*/

  const handleSocialsModalShow = () => {
    setBlurBackground(true);
    setShowSocialsModal(true);
  };

  const handleSocialsModalClose = () => {
    setBlurBackground(false);
    setShowSocialsModal(false);
  };

  return (
    <footer id="footer" className="bg-gray-800 text-carousel-wrapper">
      {/*<div className="flex items-center justify-center space-x-2">
        <h1 className="font-bold text-center text-white text-1xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-3xl contract-ca">
          CA: 0x000000000000000000000000000000000000dEaD
        </h1>
        <button onClick={copyToClipboard} className="mt-0 text-white hover:text-gray-400 copy-ca-button">
          <FaCopy />
        </button>
        {copySuccess && <span className="text-xl text-white">{copySuccess}</span>}
      </div>*/}

            {/*<button className="socials" onClick={handleSocialsModalShow}>
              <img draggable="false" src={socials} alt="Socials" title='Socials' className="ranking-image" />
            </button>*/}

      <div className="flex justify-center text-xl text-white social-footer" style={{fontFamily:'Luki, Arial, sans-serif'}}>
        Copyrait © 2024 - My Fucking Cryptos
      </div>
      <ModalSocials show={showSocialsModal} onClose={handleSocialsModalClose} />
    </footer>
  );
};

export default Footer;
