import React, { useEffect, useState } from 'react';

const AnimatedCounter = ({ target }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 2000; // Duration of the animation in ms
    const increment = target / (duration / 10); // Increment per frame (assuming 60fps)

    const updateCount = () => {
      start += increment;
      if (start < target) {
        setCount(Math.floor(start));
        requestAnimationFrame(updateCount);
      } else {
        setCount(target);
      }
    };

    requestAnimationFrame(updateCount);
  }, [target]);

  return <span>{count}</span>;
};

export default AnimatedCounter;
