// src/components/ModalSocials.js
import React from 'react';
import twitter from './twitter_white.png';
import telegram from './telegram_white.png';
import dexs from './dexs_white.png';
import dext from './dext_white.png';
import uni from './uni_white.png';
import gitbook from './gitbook_white.png';

const ModalSocials = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 backdrop-blur" onClick={handleBackdropClick}>
      <div className="max-w-md p-4 mx-auto text-center text-white bg-black border-2 border-white rounded-lg shadow-lg">
        <h2 className="mb-2 text-6xl">Our Socials</h2>
          <img draggable="false" src={`${process.env.PUBLIC_URL}/our-socials.jpg`} alt="Our Socials" className="mb-4" />
        <p className="mb-4 text-3xl">Follow us on our Social Channels!</p>
        <div className="grid grid-cols-3 gap-4 place-items-center">
          <a href="https://x.com/FuckingCryptos" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
            <img draggable="false" src={twitter} alt="Twitter" title='Twitter / X' className="twitter-button2" />
          </a>
          <a href="https://t.me/myfuckingcryptos" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
            <img draggable="false" src={telegram} alt="Telegram Channel" title='Telegram' className="twitter-button2" />
          </a>
          <a href="https://app.uniswap.org" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
            <img draggable="false" src={uni} alt="UniSwap" title='UniSwap' className="twitter-button2" />
          </a>
          <a href="https://www.dexscreener.com" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
            <img draggable="false" src={dexs} alt="DexScreener" title='DexScreener' className="twitter-button2" />
          </a>        
          <a href="https://www.dextools.io" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
            <img draggable="false" src={dext} alt="DexTools" title='DexTools' className="twitter-button2" />
          </a>
          <a href="https://www.gitbook.io" target="_blank" rel="noopener noreferrer" className="text-2xl text-white hover:text-gray-400">
            <img draggable="false" src={gitbook} alt="Gitbook" title='Gitbook' className="twitter-button2" />
          </a> 
        </div>
      </div>
    </div>
  );
};

export default ModalSocials;
