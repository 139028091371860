import React, { useState } from 'react';
import supabase from '../supabaseClient';
import { useAddress } from '@thirdweb-dev/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuoteForm = () => {
  const [quote, setQuote] = useState('');
  const address = useAddress();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!address) {
      alert("Connect your wallet first");
      return;
    }

    try {
      const { error } = await supabase
        .from('quotes')
        .insert([{ text: quote, author: address, created_at: new Date(), approved: false }]);

      if (error) {
        throw error;
      }

      setQuote('');
    } catch (error) {
      toast.error(`Error sending quote: ${error.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <textarea
          value={quote}
          onChange={(e) => setQuote(e.target.value)}
          placeholder="Enter your quote here"
          required
        />
        <button type="submit">Submit Quote</button>
      </form>
    </>
  );
};

export default QuoteForm;
