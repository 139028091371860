// src/components/AuthHandler.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import supabase from '../supabaseClient';

const AuthHandler = ({ setShowWelcome }) => {
  const location = useLocation();

  useEffect(() => {
    // Verifica si el usuario ha sido autenticado con Twitter
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        setShowWelcome(false);
      }
    });

    // Verifica si hay un parámetro de autenticación en la URL
    const params = new URLSearchParams(location.search);
    if (params.get('auth') === 'success') {
      setShowWelcome(false);
      // Elimina el parámetro de autenticación de la URL para evitar que afecte futuros estados
      params.delete('auth');
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [location, setShowWelcome]);

  return null;
};

export default AuthHandler;