export const determineBackgroundClass = (text) => {
    // Convierte el texto a minúsculas y lo divide en palabras
    const words = text.toLowerCase().split(/\s+/);
  
    // Define las palabras clave con sus respectivas clases
    const keywordMap = {
        ////////////////////////// Coins
        btc: "btc-background",
        bitcoin: "btc-background",
        eth: "eth-background",
        ethereum: "eth-background",
        sol: "sol-background",
        solana: "sol-background",
        polka: "polkadot-background",
        polkadot: "polkadot-background",
        terra: "terra-luna-background",
        luna: "terra-luna-background",
        bnb: "bnb-background",
        xrp: "xrp-background",
        ripple: "xrp-background",
        doge: "doge-background",
        dogecoin: "doge-background",
        ada: "ada-background",
        cardano: "ada-background",
        shiba: "shiba-background",
        shib: "shiba-background",
        kas: "kas-background",
        kaspa: "kas-background",
        matic: "matic-background",
        polygon: "matic-background",
        uni: "uni-background",
        uniswap: "uni-background",
        inj: "inj-background",
        injective: "inj-background",
        bonk: "bonk-background",
        xmr: "xmr-background",
        monero: "xmr-background",
        arb: "arb-background",
        arbitrum: "arb-background",
        paal: "paal-background",
        metamask: "metamask-background",
        trx: "trx-background",
        tron: "trx-background",
        ftx: "ftx-background",
        /////////////////////// Memecoins
        duko: "duko-background",
        mog: "mog-background",
        pepe: "pepe-background",
        wif: "wif-background",
        brett: "brett-background",
        popcat: "popcat-background",
        ponke: "ponke-background",
        neiro: "neiro-background",
        npc: "npc-background",
    };
  
    // Crear un nuevo mapa con las variantes que tienen el punto.
    const extendedKeywordMap = {};
    for (const [key, value] of Object.entries(keywordMap)) {
        extendedKeywordMap[key] = value; // Sin punto
        extendedKeywordMap[`${key}.`] = value; // Con punto al final
        extendedKeywordMap[`$${key}`] = value; // Con $ al principio
    }
  
    // Recorre las palabras del texto y verifica si alguna coincide con una palabra clave
    for (let word of words) {
        if (extendedKeywordMap[word]) {
            return extendedKeywordMap[word]; // Retorna la clase correspondiente a la primera palabra encontrada
        }
    }
  
    return ""; // Retorna una cadena vacía si no coincide con ninguna palabra clave
  };
  