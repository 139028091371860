import React, { useEffect, useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import supabase from '../supabaseClient';
import AnimatedCounter from './AnimatedCounter';
import TotalVotes from './TotalUserVotes';
import previous from './previous.png';
import next from './next.png';
import Socials from './Socials';

const MyReferrals = ({ referralCode }) => {
  const address = useAddress();
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // 10 items per page
  const totalPages = Math.ceil(referrals.length / itemsPerPage);
  const [stats, setStats] = useState({
    totalPoints: 0,
    quotesSubmitted: 0,
    votesCast: 0,
    friendsReferred: 0,
    votesReceived: 0,
    walletVoteCount: 0,
    referralPoints: 0,
    bonusPoints: 0,
  });

  useEffect(() => {
    if (referralCode) {
      fetchStats();
      fetchUserName();
      fetchReferrals();
    }
  }, [referralCode]);

  const fetchReferrals = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('referrals_with_quotes')
        .select(`
          *,
          users (
            wallet_address,
            twitter_username,
            profile_image
          )
        `)
        .eq('referral_code', referralCode);

      if (error) {
        throw error;
      }

      if (data.length === 0) {
        console.log('No referrals found');
        setReferrals([]);
      } else {
        const referralUserIds = data.map(referral => referral.user_id);
        const { data: userStats, error: statsError } = await supabase
          .from('user_stats')
          .select('wallet_address, total_points')
          .in('wallet_address', referralUserIds);

        if (statsError) {
          throw statsError;
        }

        const referralsWithPoints = data.map(referral => {
          const userStatsEntry = userStats.find(stats => stats.wallet_address === referral.user_id);
          return {
            ...referral,
            total_points: userStatsEntry ? userStatsEntry.total_points : 0,
          };
        });

        setReferrals(referralsWithPoints);
      }
    } catch (error) {
      console.error('Error fetching referrals:', error.message);
      setReferrals([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    if (!address) {
      console.error('Error: address is null or undefined');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('user_stats')
        .select('*')
        .eq('wallet_address', address)
        .single();

      if (error) {
        throw error;
      }

      setStats(data);
    } catch (error) {
      console.error('Error fetching stats:', error.message);
    }
  };

  const fetchUserName = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('twitter_username')
        .eq('wallet_address', address)
        .single();

      if (error) {
        throw error;
      }

      if (data) {
        setUserName(data.twitter_username || shortenAddress(address));
      } else {
        setUserName(shortenAddress(address));
      }
    } catch (error) {
      console.error('Error fetching user name:', error.message);
    }
  };

  const copyToClipboard = () => {
    const referralUrl = `${window.location.origin}/signup?ref=${referralCode}`;
    navigator.clipboard.writeText(referralUrl);
    alert('Referral link copied to clipboard!');
  };

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 7)}...${address.slice(-5)}`;
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <><div className="signup-container">
      <div className="text-carousel-wrapper" style={{ background: '#588c39' }}>
        <h1 className="text-6xl font-bold text-white centered-title carousel-title">
          Farm Airdrop MFER!
        </h1>

        <Socials />

        <div className="text-carousel text-align-center carousel-subtitle">
          <h3 className="text-4xl font-bold text-white">
            Invite your friends and farm airdrops like a degen! The more friends and quotes you get, the more points you earn for the airdrop
          </h3>
        </div>
      </div>
      {loading ? (
        <div className="referral-container">
          <p>Loading... Wait a moment fren!</p>
        </div>
      ) : (
        <div className="referral-container">
          <p className="text-4xl font-bold text-black">Your referral code: {referralCode}</p>
          <img
            className="referral-link-button"
            src="/copy-ref.png"
            alt="Copy Referral Link"
            onClick={copyToClipboard}
            style={{ width: '250px', height: 'auto', marginBottom: '2em' }} />
        </div>
      )}

      <div className="w-full max-w-6xl mx-auto mb-2 text-center bg-white" style={{ paddingLeft: '0.1em', paddingRight: '0.1em' }}>
        <h2 className="text-3xl font-bold text-black"><i>Hey {userName}! Here’s your fucking stats for the airdrop, keep grinding mfer!</i></h2>
      </div>

      <div className="flex items-center justify-center w-full max-w-6xl p-2 mx-auto mb-6 space-x-4 text-center bg-gray-200" style={{ border: '2px solid black', width: '95%' }}>
        <img draggable="false" className="wojak-points" src="/wojak-points.png" alt="Wojak Points" />
        <div className="text-center">
          <div className="text-4xl font-bold">YOUR POINTS</div>
          <div className="text-5xl font-bold"><AnimatedCounter target={stats.total_points} /></div>
        </div>
        <img draggable="false" className="wojak-points" src="/wojak-points2.png" alt="Wojak Points" />
      </div>


      <div className="flex flex-col justify-between w-full max-w-6xl mx-auto md:flex-row">
        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto mb-6 text-center bg-yellow-200 md:mb-0 md:mr-4" style={{ paddingTop: '1.5em', paddingBottom: '1.5em', border: '2px solid black', width: '95%' }}>
          <img draggable="false" className="wojak-points" src="/wokaj-submitted.png" alt="Wojak Stories" style={{ paddingBottom: '0.75em' }} />
          <div className="text-4xl font-bold">
            <span className="block md:hidden">STORIES SUBMITTED</span>
            <span className="hidden md:block">STORIES<br />SUBMITTED</span>
          </div>
          <div className="text-5xl font-bold"><AnimatedCounter target={stats.quotes_submitted} /></div>
          <div className="text-xl font-bold">250 Points per Story (Max 1250)</div>
        </div>

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto mb-6 text-center bg-green-200 md:mb-0 md:mx-4" style={{ paddingTop: '1.5em', paddingBottom: '1.5em', border: '2px solid black', width: '95%' }}>
          <img draggable="false" className="wojak-points" src="/wokaj-cast.png" alt="Wojak Cast" style={{ paddingBottom: '0.75em' }} />
          <div className="text-4xl font-bold">
            <span className="block md:hidden">VOTES CAST</span>
            <span className="hidden md:block">VOTES<br />CAST</span>
            <TotalVotes />
            <div className="text-xl font-bold">25 Points per Vote Cast</div>
          </div>
        </div>

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto mb-6 text-center bg-red-200 md:mb-0 md:mx-4" style={{ paddingTop: '1.5em', paddingBottom: '1.5em', border: '2px solid black', width: '95%' }}>
          <img draggable="false" className="wojak-points" src="/wokaj-received.png" alt="Wojak Received" style={{ paddingBottom: '0.75em' }} />
          <div className="text-4xl font-bold">
            <span className="block md:hidden">VOTES RECEIVED</span>
            <span className="hidden md:block">VOTES<br />RECEIVED</span>
          </div>
          <div className="text-5xl font-bold"><AnimatedCounter target={stats.votes_received} /></div>
          <div className="text-xl font-bold">10 Points per Vote Received</div>
        </div>

        <div className="flex flex-col items-center flex-1 h-64 p-8 mx-auto text-center bg-blue-300 md:mb-0 md:ml-4" style={{ paddingTop: '1.5em', paddingBottom: '1.5em', border: '2px solid black', width: '95%' }}>
          <img draggable="false" className="wojak-points" src="/wokaj-friends.png" alt="Wojak Friends" style={{ paddingBottom: '0.75em' }} />
          <div className="text-4xl font-bold">
            <span className="block md:hidden">FRENS REFERRED</span>
            <span className="hidden md:block">FRENS<br />REFERRED</span>
          </div>
          <div className="text-5xl font-bold"><AnimatedCounter target={stats.friends_referred} /></div>
          <div className="text-xl font-bold">
            10+ Frens: 1,000 Points Bonus</div>
        </div>
      </div>

      <div className="flex flex-col justify-between w-full max-w-6xl mx-auto mt-4 md:flex-row">
        <div className="flex items-center justify-center w-full max-w-6xl mx-auto mb-6 text-center bg-gray-200" style={{ paddingLeft: '0.25em', paddingRight: '0.25em', paddingTop: '1.5em', paddingBottom: '1.5em', border: '2px solid black', width: '95%' }}>
          <img draggable="false" className="mr-2 wojak-bonus-points" src="/wojak-bonus-points.png" alt="Wojak Bonus Points" />
          <div className="flex flex-col items-center mx-1">
            <div className="mb-1 text-4xl font-bold">
              <span className="block md:hidden">BONUS POINTS</span>
              <span className="hidden md:block">BONUS POINTS</span>
            </div>
            <div className="mb-1 font-bold text-1xl">5% OF REFERRAL POINTS</div>
            <div className="text-5xl font-bold"><AnimatedCounter target={stats.bonus_points} /></div>
          </div>
          <img draggable="false" className="ml-2 wojak-bonus-points" src="/wojak-bonus-points2.png" alt="Wojak Bonus Points" />
        </div>

        <div className="flex items-center justify-center w-full max-w-6xl p-3 mx-auto mb-6 text-center bg-black" style={{ paddingTop: '1.5em', paddingBottom: '1.5em', width: '95%' }}>
          <img draggable="false" className="mr-3 wojak-total-points" src="/wojak-total-points.png" alt="Wojak Total Points" />
          <div className="flex flex-col items-center mx-1">
            <div className="text-4xl font-bold text-white">
              <span className="block md:hidden">TOTAL POINTS</span>
              <span className="hidden md:block">TOTAL POINTS</span>
            </div>
            <div className="font-bold text-white text-1xl">YOUR POINTS + BONUS POINTS</div>
            <div className="text-5xl font-bold text-white"><AnimatedCounter target={stats.total_points + stats.bonus_points} /></div>
          </div>
          <img draggable="false" className="ml-3 wojak-total-points" src="/wojak-total-points2.png" alt="Wojak Total Points" />
        </div>
      </div>

      <div className="referrals-table-container">
        <h2 className="text-4xl font-bold text-black centered-title">Your Degen Frens!</h2>
        <div className="referrals-table-wrapper">
          <table className="referrals-table" style={{ border: '2px solid black' }}>
            <thead>
              <tr>
                <th>User / Wallet</th>
                <th>Sign Up Date</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {referrals.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((referral) => (
                <tr key={referral.user_id}>
                  <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {referral.users && referral.users.twitter_username ? (
                      <>
                        <img
                          src={referral.users.profile_image}
                          alt={referral.users.twitter_username}
                          className="profile-image"
                          style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '0.5em' }}
                        />
                        {referral.users.twitter_username}
                      </>
                    ) : (
                      shortenAddress(referral.user_id)
                    )}
                  </td>
                  <td>{new Date(referral.created_at).toLocaleDateString()}</td>
                  <td><AnimatedCounter target={referral.total_points}/></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em', marginBottom: '1em', alignItems: 'center' }}>
            {page > 1 && (
              <button onClick={handlePreviousPage} style={{ padding: '0.5em 1em', border: 'none', background: 'none', cursor: 'pointer' }}>
                <img className="previous-button button-image" draggable="false" src={previous} alt="previous" />
              </button>
            )}
            {page < totalPages && (
              <button onClick={handleNextPage} style={{ padding: '0.5em 1em', border: 'none', background: 'none', cursor: 'pointer' }}>
                <img className="next-button button-image" draggable="false" src={next} alt="next" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MyReferrals;
