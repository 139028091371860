import React, { createContext, useState, useRef, useEffect } from 'react';

export const MusicContext = createContext();

export const MusicProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio('/music.mp3'));

  useEffect(() => {
    const audio = audioRef.current;
    audio.loop = true; // Activar el loop para la música
    return () => {
      audio.pause();
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <MusicContext.Provider value={{ isPlaying, togglePlayPause }}>
      {children}
    </MusicContext.Provider>
  );
};