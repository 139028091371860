import React, { createContext, useState, useEffect } from 'react';
import supabase from './supabaseClient';

const QuotesContext = createContext();

export const QuotesProvider = ({ children }) => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuotes = async () => {
      const { data, error } = await supabase
        .from('quotes')
        .select('*')
        .eq('published', true)     // Añadir esta línea
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching quotes:', error.message);
      } else {
        setQuotes(data);
      }
      setLoading(false);
    };

    fetchQuotes();
  }, []);

  const addVote = (quoteId) => {
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) =>
        quote.id === quoteId ? { ...quote, votes: quote.votes + 1 } : quote
      )
    );
  };

  return (
    <QuotesContext.Provider value={{ quotes, setQuotes, loading, addVote }}>
      {children}
    </QuotesContext.Provider>
  );
};

export default QuotesContext;
