import React, { useState, useEffect } from 'react';
import vainance from './1vainance.png';
import soylana from './2soylana.png';
import pumpdump from './3pumpdump.png';
import coinmarketcup from './3coinmarketcup.png';
import mcdonas from './4mcdonas.png';
import faiverr from './5faiverr.png';
import blackcock from './6blackcock.png';
import nedger from './7nedger.png';
import gayscale from './8gayscale.png';
import vangeek from './9vangeek.png';
import coinface from './10coinface.png';
import './Partners.css';

const images = [
  vainance,
  soylana,
  pumpdump,
  mcdonas,
  faiverr,
  blackcock,
  nedger,
  gayscale,
  vangeek,
  coinface
];

const Partners = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(updateSlideNumber());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setVisibleSlides(updateSlideNumber(width));
      setIsMobile(width < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500); 

    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="mt-5 carousel">
      <h2 className="mb-0 text-center partners-title">Our Fucking Partners</h2>
      <hr />
      <div className="carousel-track-container">
        <ul
          className="carousel-track"
          style={{ transform: `translateX(-${currentIndex * (100 / visibleSlides)}%)` }}
        >
          {images.concat(images.slice(0, visibleSlides)).map((src, index) => (
            <li key={index} className="carousel-slide" style={{ width: `${100 / visibleSlides}%` }}>
              <img src={src} alt={`Slide ${index}`} />
            </li>
          ))}
        </ul>
      </div>
      {!isMobile && (
        <>
          <button className="carousel-button left-button" onClick={handlePrev}>←</button>
          <button className="carousel-button right-button" onClick={handleNext}>→</button>
        </>
      )}
    </div>
  );
};

const updateSlideNumber = (width = window.innerWidth) => {
  return width < 768 ? 3 : 6; // Mostrar 3 imágenes en móviles, 6 en escritorio
};

export default Partners;
