import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Insert the Google Analytics script
    const script1 = document.createElement('script');
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-WW5NR883ND";
    script1.async = true;
    document.head.appendChild(script1);

    // Insert the gtag initialization script
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-WW5NR883ND');
    `;
    document.head.appendChild(script2);

    return () => {
      // Cleanup script elements when component is unmounted
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return null; // This component does not render anything
};

export default GoogleAnalytics;
