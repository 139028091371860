import React, { useEffect, useState, useCallback } from 'react';
import { useAddress, useMetamask } from '@thirdweb-dev/react';
import { useLocation } from 'react-router-dom';
import supabase from '../supabaseClient';
import airdroprules from './airdrop-rules.png';
import howworks from './how-works.png';
import registernow from './register-now.png';
import Socials from './Socials';
import { ConnectWallet, darkTheme } from '@thirdweb-dev/react';

const clientId = "45358a6e8539f50fffe1487854fb22a9";

const customTheme = darkTheme({
  colors: {
    modalBg: "#ffffff",
    walletSelectorButtonHoverBg: "red",
    borderColor: "black",
    primaryText: "black",
    secondaryText: "black",
    separatorLine: "white",
    accentText: "red",
    secondaryButtonText: "black",
  },
});

const SignUp = () => {
  const address = useAddress();
  const connectWithMetamask = useMetamask();
  const location = useLocation();
  const [referralCode, setReferralCode] = useState('');
  const [manualReferralCode, setManualReferralCode] = useState('');
  const [referrerWallet, setReferrerWallet] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [setLatestQuotes] = useState([]);
  const [isMobile] = useState(window.innerWidth <= 600);

  const fetchReferrerWallet = async (referralCode) => {
    try {
      const { data: referrals, error } = await supabase
        .from('referrals')
        .select('user_id')
        .eq('referral_code', referralCode);

      if (error || referrals.length !== 1) {
        console.error('Invalid referral code:', error ? error.message : 'No referral found or multiple rows returned');
        setErrorMessage('Invalid referral code.');
        setSuccessMessage('');
      } else {
        setReferrerWallet(referrals[0].user_id);
        console.log("Referrer wallet set to:", referrals[0].user_id);
        setErrorMessage('');
        setSuccessMessage('Referral code verified successfully!');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setErrorMessage('An unexpected error occurred.');
    }
  };

  const handleUserRegistration = useCallback(async (walletAddress, referralCode) => {
    try {
      const { data: existingUser, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('id', walletAddress)
        .single();

      if (userError && userError.code !== 'PGRST116') {
        console.error('Error fetching user:', userError.message);
        return;
      }

      if (!existingUser) {
        const { data: newUser, error: insertError } = await supabase
          .from('users')
          .insert([{ id: walletAddress, wallet_address: walletAddress }])
          .single();

        if (insertError && insertError.code !== '23505') {
          console.error('Error registering user:', insertError.message);
          return;
        }

        if (!insertError) {
          console.log('New user registered:', newUser);
        }
      }

      const code = generateReferralCode();
      const { error: insertReferralError } = await supabase
        .from('referrals')
        .insert([{ user_id: walletAddress, referral_code: code }]);

      if (insertReferralError && insertReferralError.code !== '23505') {
        console.error('Error creating referral code:', insertReferralError.message);
      } else {
        console.log("New referral code created:", code);
      }

      if (referralCode) {
        const { data: referral, error: referralError } = await supabase
          .from('referrals')
          .select('*')
          .eq('referral_code', referralCode)
          .single();

        if (referralError) {
          console.error('Error fetching referral:', referralError.message);
          return;
        }

        if (referral && !referral.referred_user_id) {
          const { data: updatedReferral, error: updateError } = await supabase
            .from('referrals')
            .update({ referred_user_id: walletAddress })
            .eq('referral_code', referralCode);

          if (updateError) {
            console.error('Error linking referral:', updateError.message);
            return;
          }

          console.log('Referral linked:', updatedReferral);
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }, []);

  const handleManualReferralCode = async () => {
    console.log("Handling manual referral code...", manualReferralCode);
    if (manualReferralCode) {
      console.log('Verifying referral code:', manualReferralCode);
      try {
        const { data: referrals, error } = await supabase
          .from('referrals')
          .select('user_id')
          .eq('referral_code', manualReferralCode);
  
        if (error || referrals.length !== 1) {
          console.error('Invalid referral code:', error ? error.message : 'No referral found or multiple rows returned');
          setErrorMessage('Invalid referral code.');
          setSuccessMessage('');
        } else {
          const referrerWallet = referrals[0].user_id;
  
          // Check if the user is trying to refer themselves
          if (referrerWallet === address) {
            setErrorMessage('You cannot refer yourself.');
            setSuccessMessage('');
            return;
          }
  
          setReferralCode(manualReferralCode);
          setReferrerWallet(referrerWallet);
          console.log("Manual referrer wallet set to:", referrerWallet);
  
          const { data, error } = await supabase
            .from('referrals_with_quotes')
            .insert([{ user_id: address, referral_code: manualReferralCode }]);
          
          if (error) {
            console.error('Error linking referral:', error.message);
            setErrorMessage('An unexpected error occurred.');
          } else {
            console.log('Referral linked:', data);
            setErrorMessage('');
            setSuccessMessage('Referral code verified successfully!');
          }
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };

  const generateReferralCode = () => {
    console.log("Generating referral code..."); // This will confirm the function is being called
    const randomPart = Math.random().toString(36).substring(2, 10);
    const newCode = 'mfc-' + randomPart;
    console.log("Generated referral code: ", newCode); // This will show the actual generated code
    return newCode;
  };

  const checkReferralStatus = async () => {
    console.log('Checking referral status for:', address);
    try {
      const { data, error } = await supabase
        .from('referrals_with_quotes')
        .select("referral_code")
        .eq('user_id', address);
      
      if (error) {
        throw error;
      }
  
      if (data && data.length > 0) {
        setReferralCode(data[0].referral_code);
        await fetchReferrerWallet(data[0].referral_code);
      } else {
        console.log('No referral code found for this address');
        setReferralCode(null);
      }
    } catch (error) {
      console.error('Error checking referral status:', error.message);
      // Optionally set an error state here
      // setError('Failed to check referral status');
    }
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    if (ref) {
      console.log("Referral code found in URL:", ref);
      setReferralCode(ref);
      fetchReferrerWallet(ref);
      setManualReferralCode(ref);
    }
  }, [location]);

  useEffect(() => {
    if (address) {
      handleUserRegistration(address, referralCode);
      checkReferralStatus();
    }
  }, [address, referralCode, handleUserRegistration]);

  return (
    <>
      <div className="text-center signup-container">
        <div className="text-carousel-wrapper" style={{background:'#588c39'}}>
          <h1 className="text-6xl font-bold text-white carousel-title" style={{paddingTop:'0.1em', marginBottom:'10px'}}>
            {isMobile ? 'Join the Airdrop' : 'Publish Stories and Join the Airdrop'}
          </h1>

          <Socials />

          <div className="text-carousel text-align-center carousel-subtitle">
          <h3 className="text-4xl font-bold text-white">
            If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
          </h3>
        </div>          
        </div>
        {referrerWallet && (
            <div>
          <p className="mt-4 text-3xl text-black"><i>You were invited by:</i> <strong><span className='wallet-mobile'>{referrerWallet}</span></strong></p>
          {/*<h2 className="mt-4 text-4xl text-white text-bold" style={{background:'#588c39', borderRadius:'5px', width:'fit-content', paddingLeft:'15px', paddingRight:'15px', maxWidth:'90%', margin:'0 auto'}}>
            Wallet successfully connected!
          </h2>*/}
          </div>
        )}
        {!referralCode && (
          <div style={{maxWidth:'90%'}}>
            <div>
              <h2 className="mt-4 text-4xl text-black text-bold invited-by" style={{fontWeight:'bold'}}>
                Have you been invited by someone?
              </h2>
              <p className="text-2xl text-black">
              Connect your wallet to earn more points!
              </p>
              <div className="manual-referral-code-container">
                <input
                  type="text"
                  placeholder="Enter referral code"
                  value={manualReferralCode}
                  onChange={(e) => setManualReferralCode(e.target.value)}
                  className="manual-referral-code-input"
                />
                {/* <button onClick={handleManualReferralCode} className="manual-referral-code-button">
                  Verify Referral Code 
                </button> */}
                <button onClick={handleManualReferralCode} className="manual-referral-code-button">
                  CONNECT WITH REFERRER
                </button>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
              </div>
              <div>
                <h2 className="mt-5 text-4xl text-black text-bold invited-by" style={{fontWeight:'bold'}}>
                  You can register even without a referral code
                </h2>
                <p className="text-2xl text-black">
                  Simply connect your wallet and become part of the MFC family!
                </p>
              </div>
            </div>
          </div>
        )}
          {!address ? (
            <ConnectWallet className="register-now connect-wallet-btn" theme={customTheme} client={clientId} switchToActiveChain={true} showThirdwebBranding={false}
            modalTitle="My Fucking Cryptos"
            modalTitleIconUrl="https://i.ibb.co/w6Y8PYw/icon.png"
            welcomeScreen={{
              title: "YOUR CRYPTO STORIES MATTER",
              img: {
                src: "https://i.ibb.co/ZMycjN2/tg-pfp-channel-copia.png",
                width: 150,
                height: 150,
              },
            }}
          />  
          ) : (
            <>
              {/* <p className="mt-4 text-3xl text-black"><i>Wallet Connected: <span className='wallet-mobile'>{address}</span></i></p> */}
            </>
          )}

        <div className="flex flex-col justify-center mt-8 space-y-4 md:flex-row md:space-y-0 md:space-x-2" style={{maxWidth:'90%'}}>
          <div className="flex flex-col w-full p-8 text-white rounded-lg md:w-1/3 bg-gris">
          <div className="flex items-center justify-center mb-4">
              <img draggable="false" src={howworks} alt="How it Works" style={{ marginRight: '1.5rem', height:'65px' }} />
              <h2 className="text-4xl font-bold">How it Works</h2>
            </div>
            <ol className="space-y-2 text-2xl">
              <li><strong><u>STEP 1</u> ⇨</strong> <i>Connect your wallet</i></li>
              <li><strong><u>STEP 2</u> ⇨</strong> <i>Post one or more stories</i></li>
              <li><strong><u>STEP 3</u> ⇨</strong> <i>Vote and Cast Votes</i></li>
              <li><strong><u>STEP 4</u> ⇨</strong> <i>Accumulate points</i></li>
              <li><strong><u>STEP 5</u> ⇨</strong> <i>Invite your friends!</i></li>
            </ol>
          </div>
          <div className="flex flex-col w-full p-8 text-white rounded-lg md:w-1/3 bg-grana">
            <div className="flex items-center justify-center mb-4">
              <img draggable="false" src={airdroprules} alt="Airdrop Rules" style={{ marginRight: '1.5rem', height:'60px' }} />
              <h2 className="text-4xl font-bold">Airdrop Rules</h2>
            </div>
            <p className="mb-4 text-2xl">
 A small percentage of the supply is reserved for an airdrop that will be made after tge. For now, the best thing you can do is to farm points in My Fucking Cryptos.
</p>
<p className="text-2xl">
  <u>Spam or botting stories is prohibited. Any user detected using fraudulent techniques will be automatically disqualified.</u>
</p>
<p className="text-2xl">
  The use of fraudulent techniques to accumulate extra points will be grounds for disqualification.
</p>

          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
