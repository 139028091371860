import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ConnectWallet, useAddress, darkTheme } from '@thirdweb-dev/react';
import { MusicContext } from '../MusicContext';
import QuoteModal from './QuoteModal';
import InstallModal from './InstallModal'; // Importa el nuevo modal
import { FaPlay, FaPause, FaBars } from 'react-icons/fa';
import add_new from './add_new.png';
import add_new2 from './add_new3.png';
import admin from './admin2.png';
import admin2 from './admin.png';
//import uploads from './uploads.png';
import airdrop from './airdrop2.png';
import ranking from './ranking.png';
import app from './app.png';
import socials from './socials.png';
import calculator from './calculator.png';
import linkx from './linkx.png';
import linkx2 from './linkx2.png';
import howitworks from './how-it-works.png';
import supabase from '../supabaseClient';
import LinkXModal from './LinkXModal';
import ModalSocials from './ModalSocials'; // Importa el nuevo modal

const clientId = "45358a6e8539f50fffe1487854fb22a9";

const customTheme = darkTheme({
  colors: {
    modalBg: "#ffffff",
    walletSelectorButtonHoverBg: "red",
    borderColor: "black",
    primaryText: "black",
    secondaryText: "black",
    separatorLine: "white",
    accentText: "red",
    secondaryButtonText: "black",
  },
});

const Header = ({ resetWelcome, setBlurBackground }) => {
  const [isLinkXModalOpen, setIsLinkXModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSocialsModal, setShowSocialsModal] = useState(false); // Estado para el nuevo modal
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const address = useAddress();
  const location = useLocation();
  const { isPlaying, togglePlayPause } = useContext(MusicContext);
  const [userData, setUserData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [deferredPrompt, setDeferredPrompt] = useState(null); // Estado para almacenar el evento de instalación
  const [modalContent, setModalContent] = useState(null);
  const [showInstallModal, setShowInstallModal] = useState(false);

  const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;


  /*const handleLinkXModalShow = () => {
    setBlurBackground(true);
    setIsLinkXModalOpen(true);
  };*/

  const handleLinkXModalClose = () => {
    setBlurBackground(false);
    setIsLinkXModalOpen(false);
  };  

  const handleInstallModalOpen = () => {
    setBlurBackground(true);
    setShowInstallModal(true);
  };
  
  const handleInstallModalClose = () => {
    setBlurBackground(false);
    setShowInstallModal(false);
  };

  const ADMIN_ADDRESSES = process.env.REACT_APP_ADMIN_ADDRESSES
    ? process.env.REACT_APP_ADMIN_ADDRESSES.split(',').map(addr => addr.trim().toLowerCase())
    : [];

  const [referralCode, setReferralCode] = useState('');  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    if (ref) {
      console.log("Referral code found in URL:", ref);
      setReferralCode(ref);
    }
  }, [location]);

  useEffect(() => {
    if (address) {
      registerUserWithWallet(address, referralCode);
    }
  }, [address]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      console.log("beforeinstallprompt event captured");
      e.preventDefault();
      setDeferredPrompt(e);
    };
  
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    if (deferredPrompt) {
      console.log("deferredPrompt is set:", deferredPrompt);
    }
  }, [deferredPrompt]);  
  
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
          setShowInstallModal(true); // Mostrar el modal de instalación
        }
        setDeferredPrompt(null);
      });
    } else {
      setShowInstallModal(true); // Mostrar el modal de instalación
    }
  };  

  useEffect(() => {
    const handleUserInteraction = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
            setShowInstallModal(true); // Mostrar el modal de instalación si se rechaza
          }
          setDeferredPrompt(null); // Limpia el evento para no mostrarlo de nuevo
        });
      }
    };
  
    window.addEventListener('click', handleUserInteraction, { once: true });
  
    return () => {
      window.removeEventListener('click', handleUserInteraction);
    };
  }, [deferredPrompt]);

  const renderInstallInstructions = () => (
    <div>
      <h3></h3>
      <p>Follow the steps to install the application on your device</p>
      <h3 style={{textDecoration:'underline'}}>For Android/Chrome:</h3>
      <ol style={{fontSize:'1.1em', padding:'0', textAlign:'left'}}>
        <li>1️⃣ Open the browser menu</li>
        <li>2️⃣ Select "Add to Home screen"</li>
        <li>3️⃣ Confirm the installation in the next step</li>
      </ol>
      <h3 style={{textDecoration:'underline'}}>For iOS/Safari:</h3>
      <ol style={{fontSize:'1.1em', padding:'0', textAlign:'left'}}>
        <li>1️⃣ Open Safari's share menu (share icon)</li>
        <li>2️⃣ Select "Add to Home Screen"</li>
        <li>3️⃣ Confirm the installation in the next step</li>
      </ol>
    </div>
  );  

  const registerUserWithWallet = async (walletAddress, referralCode) => {
    try {
      const { data: existingUser, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('wallet_address', walletAddress)
        .single();
  
      if (userError && userError.code !== 'PGRST116') {
        console.error('Error fetching user data:', userError.message);
        return;
      }
  
      if (!existingUser) {
        const newUserPayload = {
          wallet_address: walletAddress,
        };
  
        if (referralCode) {
          newUserPayload.referral_code = referralCode;
        }
  
        const { data: newUser, error: insertError } = await supabase
          .from('users')
          .insert([newUserPayload])
          .single();
  
        if (insertError) {
          console.error('Error registering user:', insertError.message);
          return;
        }
  
        console.log('New user registered:', newUser);
        setUserData(newUser);
      } else {
        setUserData(existingUser);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const handleShow = () => {
    if (!address) {
      alert("Please connect your wallet first!");
      return;
    }
    setBlurBackground(true);
    setShowModal(true);
  };

  const handleClose = () => {
    setBlurBackground(false);
    setShowModal(false);
  };

  const handleLogoClick = () => {
    if (location.pathname === '/') {
      resetWelcome();
    }
  };

  const handleTwitterLogin = () => {
    console.log("Initiating Twitter login");
    if (!address) {
      alert("Please connect your wallet first!");
      return;
    }
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/twitter?wallet_address=${address}`;
  };

  const handleAirdropClick = () => {
    // Add your airdrop functionality here
  };

  const handleSocialsModalShow = () => {
    setBlurBackground(true);
    setShowSocialsModal(true);
  };

  const handleSocialsModalClose = () => {
    setBlurBackground(false);
    setShowSocialsModal(false);
  };

  const isAdmin = address && ADMIN_ADDRESSES.includes(address.toLowerCase());

  return (
    <header className={`header ${showModal || showInstallModal ? 'blur' : ''}`}>
      <div className="logo" onClick={handleLogoClick}>
        <Link to="/">
          <img draggable="false" src={isMobile ? "/logo.png" : "/logo-desktop2.png"} alt="Logo" className="logo-image" />
        </Link>
      </div>
      <div className="flex items-center space-x-4 xl:hidden">
        <button onClick={() => setSidebarOpen(true)}>
          <FaBars className="text-2xl" />
        </button>
      </div>
      <div className="hidden space-x-4 xl:flex nav-container">
        <div className="nav-links">
          <div className="hidden xl:block">
            <Link to={address ? "/referrals" : "/signup"} className="referrals">
              <button className="referrals-button">
                <img draggable="false" src={airdrop} alt="airdrop" title='Farm Airdrop' className="airdrop-image" onClick={handleAirdropClick}/>
              </button>
            </Link>
          </div>       
          <div className="hidden xl:block">
            <Link to="/ranking" className="ranking">
              <button className="referrals-button">
                <img draggable="false" src={ranking} alt="ranking" title='Ranking Airdrop' className="ranking-image"/>
              </button>
            </Link>
          </div>  
          <div className="hidden xl:block">
            <button className="submit-quote-button" onClick={handleShow}>
              <img draggable="false" src={add_new2} alt="Add New" title='Add New' className="button-image" />
            </button>
          </div>
          {isAdmin && (
            <div className="hidden xl:block">
              <Link to="/admin" className="admin">
                <img draggable="false" src={admin} alt="admin" className="button-image admin-button" />
              </Link>
            </div>
          )}
          <div className="hidden xl:block">
            {userData && userData.twitter_handle ? (
              <a href={userData.profile_url} target="_blank" rel="noopener noreferrer">
                <div className="flex items-center p-2 rounded-md twitter-box">
                  <img src={userData.profile_image} alt="Profile" className="w-12 h-12 mr-2 rounded-full" />
                </div>
              </a>
            ) : (
              <button className="submit-quote-button" onClick={handleTwitterLogin}>
                <img draggable="false" src={linkx} alt="Link X" title='Link Twitter / X' className="linkx-image" />
              </button>
            )}
          </div>
          <div className="hidden xl:block"> 
            <button className="play-pause-button" onClick={() => {
                togglePlayPause();
              }}>
                {isPlaying ? (
                  <FaPause style={{ fontSize: '25px', color: '#007bff' }} />
                ) : (
                  <FaPlay style={{ fontSize: '25px', color: '#007bff' }} />
                )}
            </button>
          </div>
          <div className="hidden xl:block">
            <ConnectWallet className="tw-connected-wallet connect-wallet-btn" onConnect={() => setIsConnected(true)} theme={customTheme} client={clientId} switchToActiveChain={true} showThirdwebBranding={false}
              modalTitle="My Fucking Cryptos"
              modalTitleIconUrl="https://i.ibb.co/w6Y8PYw/icon.png"
              welcomeScreen={{
                title: "YOUR CRYPTO STORIES MATTER",
                img: {
                  src: "https://i.ibb.co/ZMycjN2/tg-pfp-channel-copia.png",
                  width: 150,
                  height: 150,
                },
              }}
            />  
          </div>
        </div>
      </div>
      {sidebarOpen && (
        <div className="fixed inset-0 z-50 flex justify-end bg-gray-900 bg-opacity-75">
          <div className="w-80 h-full p-4 bg-white">
            <nav className="flex flex-col space-y-4">
              <ConnectWallet className="button-image connected-wallet-button-sidebar" style={{marginTop:'0.5em'}}/>
              {isAdmin && (
                <Link to="/admin" onClick={() => setSidebarOpen(false)}>
                  <img draggable="false" src={admin2} alt="admin" className="admin-button-mobile" />
                </Link>
              )}
              <button onClick={handleShow}>
                <img draggable="false" src={add_new2} alt="Add New" className="add-new-button-mobile" />
              </button>              
              <Link to={address ? "/referrals" : "/signup"} onClick={() => setSidebarOpen(false)}>
                <img draggable="false" src={airdrop} alt="airdrop" className="airdrop-button-mobile" onClick={handleAirdropClick}/>
              </Link>
              <Link to="/ranking" className="ranking" onClick={() => setSidebarOpen(false)}>
                <button className="referrals-button">
                  <img draggable="false" src={ranking} alt="ranking" title='Ranking / Leaderboard' className="airdrop-button-mobile"/>
                </button>
              </Link>   
              {userData && userData.twitter_handle ? (
                  <></>
                ) : (
                  <button className="submit-quote-button" onClick={handleTwitterLogin}>
                    <img draggable="false" src={linkx} alt="Link X" title="Link X" className="linkx-image" />
                  </button>
                )}                  
              <div>
                {!isInStandaloneMode && (
                  <button onClick={handleInstallClick}>
                    <img src={app} alt="Install App" />
                  </button>
                )}
              </div>                       
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', gap:'0px', marginLeft:'0.3em', paddingTop:'0.5em'}}>
                <button onClick={togglePlayPause} className='play-pause-button'>
                  {isPlaying ? (
                    <FaPause className="text-2xl text-blue-500" />
                  ) : (
                    <FaPlay className="text-2xl text-blue-500" />
                  )}
                </button>
              </div>     
            </nav>
          </div>
          <div className="flex-1" onClick={() => setSidebarOpen(false)} />
        </div>
      )}
      <QuoteModal show={showModal} handleClose={handleClose} />
      <InstallModal show={showInstallModal} handleClose={handleInstallModalClose} content={renderInstallInstructions()} />
      <LinkXModal show={isLinkXModalOpen} handleClose={handleLinkXModalClose} />
      <ModalSocials show={showSocialsModal} onClose={handleSocialsModalClose} />
    </header>
  );
}

export default Header;
