import React from 'react';

const Modal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 backdrop-blur" onClick={handleBackdropClick}>
      <div className="max-w-md p-4 mx-auto text-center text-white bg-black border-2 border-white rounded-lg shadow-lg">
        <h2 className="mb-2 text-6xl">Welcome Anon!</h2>
          <img draggable="false" src={`${process.env.PUBLIC_URL}/wojak-welcome.jpg`} alt="Wojak Welcome" className="mb-4" />
        <p className="mb-4 text-3xl">Did you know that voting you can <b>EARN random PRIZES</b>?</p>
        <p className="mb-4 text-3xl">Read quotes and vote to discover possible hidden rewards...</p>
        <h2 className="mb-2 text-6xl mb-3" style={{color:'white', textDecoration:'underline'}}>COMING SOON</h2>
        <div className="flex items-center justify-center mb-1">
          <span className="mr-5">Powered by</span>
            <a href="https://frenslink.com/" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/frens.svg`} alt="Frens Protocol" className="h-7" />
            </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
