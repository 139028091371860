/*import React from 'react';
import myGif from './output-onlinegiftools.gif'; // Asegúrate de tener el GIF en tu carpeta de assets

const GifComponent = ({ className }) => {
  const gifStyle = {
    position: 'fixed',
    bottom: '-20px',
    width: '300px',
    right: '0px',
    zIndex: 1 // Asegúrate de que esté en el frente
  };

  return (
    <img draggable="false" src={myGif} alt="Animated Gif" style={gifStyle} className={className} />
  );
};

export default GifComponent;*/

import React from 'react';
import wojakImage from './right-wojak.png'; // Asegúrate de tener la imagen en tu carpeta de assets

const GifComponent = ({ className }) => {
  const imageStyle = {
    position: 'fixed',
    bottom: '0px',
    width: '335px',
    right: '0px',
    zIndex: 1 // Asegúrate de que esté en el frente
  };

  return (
    <img draggable="false" src={wojakImage} alt="Left Wojak" style={imageStyle} className={className} />
  );
};

export default GifComponent;

