import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TwitterLoginButton } from 'react-social-login-buttons';

const LinkXModal = ({ show, handleClose }) => {

  const handleTwitterLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/twitter`;
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Link Your Twitter Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          If you link your Twitter/X account to our dapp, you will participate in the ranking/leaderboard and have the chance to win prizes...
        </p>
        <TwitterLoginButton onClick={handleTwitterLogin} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkXModal;
