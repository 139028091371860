import React, { useContext, useEffect, useState, useCallback, memo, useMemo } from 'react';
import axios from 'axios';
import QuotesContext from '../QuotesContext';
import supabase from '../supabaseClient';
import { MusicContext } from '../MusicContext';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import moon from './moon2.png';
import rekt from './rekt2.png';
import wallet from './wallet.png';
import previous from './previous.png';
import degenadprofile from './degen-ad.png';
import degenadmoreinfo from './more-info.png';
import next from './next.png';
import wojakinfo from './wojak-info.png';
import ModalFrens from './ModalFrens';
import './QuoteList.css';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import Socials from './Socials';
import { ethers } from 'ethers';
import Partners from './Partners';
import AnimatedCounter from './AnimatedCounter';
import { determineBackgroundClass } from './backgroundUtils';

// Función para obtener un identificador único del navegador del usuario
const getFingerprint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

// Función auxiliar para obtener un nombre y una imagen aleatorios de una lista predefinida
const getRandomNameAndImage = (predefinedNames) => {
  const randomIndex = Math.floor(Math.random() * predefinedNames.length);
  const selectedName = predefinedNames[randomIndex];
  return {
    name: selectedName.name,
    imageUrl: `${process.env.REACT_APP_API_URL}${selectedName.imageUrl}` // Aseguramos que la URL sea completa
  };
};

const QuoteList = ({ onEnter, showWelcome, setShowWelcome }) => {
  const { quotes, setQuotes } = useContext(QuotesContext);
  const { togglePlayPause } = useContext(MusicContext);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('recent');
  const [bounceOut, setBounceOut] = useState(false);
  const [showModalFrens, setShowModalFrens] = useState(false);
  const [showModalWojak, setShowModalWojak] = useState(false);
  const [ipAddress, setIpAddress] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isStandaloneMode, setIsStandaloneMode] = useState(false); // Inicialmente en false
  const [itemsPerPage, setItemsPerPage] = useState(null); // Inicializa con null para esperar a que se establezca el valor correcto
  const [totalPages, setTotalPages] = useState(1);
  const [votedQuotes, setVotedQuotes] = useState({});
  const [modalContent, setModalContent] = useState(null);
  const [showThanksBox, setShowThanksBox] = useState({});
  const [userCache, setUserCache] = useState({});
  const [quotesWithUser, setQuotesWithUser] = useState([]);
  const [walletAddress, setWalletAddress] = useState(null);
  const [fingerprint, setFingerprint] = useState(null);
  const [predefinedNames, setPredefinedNames] = useState([]);
  const [assignedNames, setAssignedNames] = useState({});  
  const [enableAds, setEnableAds] = useState(false); // Estado para manejar si los ads están habilitados
  const [showThanks, setShowThanks] = useState(false);
  const [isVoting, setIsVoting] = useState(false);


  useEffect(() => {
    const checkIsMobile = () => window.innerWidth <= 600;
    const checkIsStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
  
    // Recalcula los items por página cuando cambian el modo o el tamaño de la pantalla
    const calculateItemsPerPage = () => {
      const isMobile = checkIsMobile();
      const isStandaloneMode = checkIsStandaloneMode();
  
      // Calcula el número de items por página dependiendo de si hay anuncios o no
      const calculatedItemsPerPage = (isMobile || isStandaloneMode) ? 9 : (enableAds ? 14 : 15);
      setItemsPerPage(calculatedItemsPerPage);
    };
  
    // Ejecutar inicialmente el cálculo
    calculateItemsPerPage();
  
    // Agregar listeners para detectar cambios en el tamaño de la pantalla
    window.addEventListener('resize', calculateItemsPerPage);
  
    return () => {
      // Limpiar el listener cuando el componente se desmonte
      window.removeEventListener('resize', calculateItemsPerPage);
    };
  }, [enableAds]);  // Depende de enableAds para recalcular si cambian los anuncios
  

  // Obtener la configuración del servidor
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/config`);
        setEnableAds(response.data.enableAds); // Asignar la configuración de anuncios
        setShowThanks(response.data.showThanks); // Asignar showThanks desde el servidor
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);  

  // Función para obtener nombres predefinidos desde el backend
  const fetchPredefinedNames = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/names`);
      setPredefinedNames(response.data);
    } catch (error) {
      console.error('Error fetching predefined names:', error);
    }
  }, []);

  useEffect(() => {
    fetchPredefinedNames();
    // Fetch IP address and fingerprint
    (async () => {
      try {
        const [ipResponse, fingerprintId] = await Promise.all([
          axios.get('https://api.ipify.org?format=json'),
          getFingerprint()
        ]);
        setIpAddress(ipResponse.data.ip);
        setFingerprint(fingerprintId);
        const storedVotedQuotes = JSON.parse(localStorage.getItem(ipResponse.data.ip) || '{}');
        setVotedQuotes(storedVotedQuotes);
      } catch (error) {
        console.error('Error fetching IP address or fingerprint:', error);
      }
    })();
  }, [fetchPredefinedNames]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModalFrens(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []); 

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getWalletAddress = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const checksumAddress = ethers.utils.getAddress(accounts[0]);
        setWalletAddress(checksumAddress);
      } catch (error) {
        console.error('Error fetching wallet address:', error.message);
      }
    }
  };

  useEffect(() => {
    getWalletAddress();
  }, []);

  // Recalcular itemsPerPage cuando isMobile o isStandaloneMode cambien
  useEffect(() => {
    setItemsPerPage((isMobile || isStandaloneMode) ? 9 : 15);
  }, [isMobile, isStandaloneMode]);

  // Función para obtener las citas desde Supabase
  const fetchQuotes = useCallback(async (page, sortOrder) => {
    setLoading(true);
    try {
      let query = supabase
        .from('quotes')
        .select('*', { count: 'exact' })
        .eq('published', true);

      if (sortOrder === 'recent') query = query.order('created_at', { ascending: false });
      if (sortOrder === 'oldest') query = query.order('created_at', { ascending: true });
      if (sortOrder === 'most_voted') query = query.order('moon_votes', { ascending: false });

      query = query.range((page - 1) * itemsPerPage, page * itemsPerPage - 1);
      const { data, error, count } = await query;

      if (error) throw new Error(error.message);

      setQuotes(data.map(quote => ({ ...quote, moon_votes: quote.moon_votes ?? 0, rekt_votes: quote.rekt_votes ?? 0 })));
      setTotalPages(Math.ceil(count / itemsPerPage));
    } catch (error) {
      console.error('Error fetching quotes:', error.message);
    } finally {
      setLoading(false);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    if (itemsPerPage !== null && !showWelcome) {
      fetchQuotes(page, sortOrder);

      const subscription = supabase
        .channel('public:quotes')
        .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'quotes' }, (payload) => {
          if (payload.new.approved && payload.new.published) {
            setQuotes(prevQuotes => [payload.new, ...prevQuotes]);
          }
        })
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'quotes' }, (payload) => {
          if (payload.new.approved && payload.new.published) {
            setQuotes(prevQuotes => prevQuotes.map(quote => (quote.id === payload.new.id ? payload.new : quote)));
          }
        })
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [fetchQuotes, page, sortOrder, showWelcome, itemsPerPage]);

    // Verificar los votos del usuario al cargar la página
    useEffect(() => {
      const fetchVotes = async () => {
        if (!ipAddress || (!walletAddress && !fingerprint)) {
          //console.log('IP Address or User ID not available.');
          return;
        }
      
        let userId = walletAddress || fingerprint;     
      
        try {
          const { data: existingVotes, error: fetchError } = await supabase
            .from('user_votes')
            .select('*')
            .or(`user_address.eq.${userId},ip_address.eq.${ipAddress}`);
      
          if (fetchError) {
            console.error('Error fetching user votes:', fetchError.message);
            return;
          }
      
          if (!existingVotes || existingVotes.length === 0) {
            console.log('No votes found for user.');
            return;
          }
      
          const votesMap = {};
          existingVotes.forEach(vote => {
            votesMap[vote.quote_id] = {
              userId: vote.user_address,
              ipAddress: vote.ip_address,
              col: vote.vote_type
            };
          });

          setVotedQuotes(votesMap);
        } catch (error) {
          console.error('Error fetching user votes:', error.message);
        }
      };
  
      fetchVotes();
    }, [ipAddress, walletAddress, fingerprint]);

  // Función para manejar el voto del usuario
  const handleVote = async (quoteId, col) => {
    if (!ipAddress) {
      console.error('IP address not available.');
      return;
    }
  
    let userId;
    if (walletAddress) {
      userId = walletAddress;
    } else {
      userId = fingerprint;
    }
  
    setIsVoting(true); // Desactivar botones mientras se realiza el voto
  
    // Actualizar el estado visualmente de inmediato sin esperar respuesta del servidor
    setQuotes((prevQuotes) =>
      prevQuotes.map((quote) => {
        const currentVotes = quote[col] || 0; // Si no tiene valor, iniciar en 0
        return quote.id === quoteId ? { ...quote, [col]: currentVotes + 1 } : quote;
      })
    );
  
      // Comprobar si el cuadro de agradecimiento está habilitado desde el servidor antes de mostrarlo
      if (showThanks) {
        setShowThanksBox((prev) => ({ ...prev, [quoteId]: true }));
      }
  
    try {
      // Registrar el voto en el backend (sin esperar la respuesta para actualizar la interfaz)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quoteId, userId, ipAddress, voteType: col }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error voting');
      }
  
      // Aquí podemos manejar una respuesta positiva si necesitamos, pero ya actualizamos la UI previamente.
      const voteData = await response.json();
      console.log('Vote added to Supabase', voteData);
  
      // Guardar el voto en la lista de citas votadas
      setVotedQuotes((prevVotedQuotes) => ({
        ...prevVotedQuotes,
        [quoteId]: { userId, col }
      }));
  
    } catch (error) {
      console.error('Error voting:', error.message);
  
      // Revertir el cambio visual si hay error en el servidor
      setQuotes((prevQuotes) =>
        prevQuotes.map((quote) => {
          const currentVotes = quote[col] || 0;
          return quote.id === quoteId ? { ...quote, [col]: currentVotes - 1 } : quote;
        })
      );
      if (showThanks) {
        setShowThanksBox((prev) => ({ ...prev, [quoteId]: false })); // Ocultar el mensaje si hay error
      }
    } finally {
      setIsVoting(false); // Reactivar los botones después de la votación
    }
  };
  
  
  const handleCheckClick = (quote) => {
    setModalContent(`You voted for ${quote.text}`);
    setShowModalFrens(true);
    setShowThanksBox({ ...showThanksBox, [quote.id]: false });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setPage(1);
  };

  // Función para manejar la navegación entre páginas
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Función para manejar la navegación entre páginas
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleEnterClick = () => {
    setBounceOut(true);
    setTimeout(() => {
      setShowWelcome(false);
      togglePlayPause();
      if (onEnter) {
        onEnter();
      }
    }, 1000);
  };

  const handleCloseModalFrens = () => {
    setShowModalFrens(false);
    setModalContent(null);
  };

  const handleCloseModalWojak = () => {
    setShowModalWojak(false);
  };

  const fetchUserData = async (walletAddresses) => {
    const uncachedAddresses = walletAddresses.filter(addr => !userCache[addr]);
    if (uncachedAddresses.length === 0) {
      return userCache;
    }
  
    try {
    const { data: usersData, error: usersError } = await supabase
      .from('users')
      .select('*')
      .in('wallet_address', uncachedAddresses);
  
      if (usersError) {
        console.error(usersError);
        return userCache;
      }
  
      const newUserCache = { ...userCache };
      usersData.forEach(user => {
        newUserCache[user.wallet_address] = user;
      });
  
      setUserCache(newUserCache);
      return newUserCache;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return userCache;
    }
  };  
   
  useEffect(() => {
    const fetchQuotesWithUser = async () => {
      setLoading(true);
      try {
      const itemsPerPage = window.innerWidth <= 600 ? 9 : 15;
      const { data: quotesData, error: quotesError } = await supabase
        .from('quotes')
        .select('*')
        .eq('published', true)
        .order('created_at', { ascending: false })
        .range((page - 1) * itemsPerPage, page * itemsPerPage - 1);
  
      if (quotesError) {
        console.error(quotesError);
        setLoading(false);
        return;
      }
  
      const userIds = quotesData.map(quote => quote.author);
      const updatedUserCache = await fetchUserData(userIds);
  
      // Asignar nombres aleatorios a usuarios sin twitter_username
      const newAssignedNames = {};
      const quotesWithUser = quotesData.map((quote, index) => {
        let user = updatedUserCache[quote.author] || { twitter_username: null, profile_image: wallet, id: index };
        if (!user.twitter_username) {
          if (!assignedNames[user.id] && predefinedNames.length > 0) {
            const randomNameData = getRandomNameAndImage(predefinedNames);
            newAssignedNames[user.id] = { name: randomNameData.name + user.id, imageUrl: randomNameData.imageUrl };
          }
          user.twitter_username = newAssignedNames[user.id]?.name || assignedNames[user.id]?.name;
          user.profile_image = newAssignedNames[user.id]?.imageUrl || assignedNames[user.id]?.imageUrl;
        }
        return { ...quote, user };
      });
  
      setAssignedNames(prev => ({ ...prev, ...newAssignedNames }));
      setQuotesWithUser(quotesWithUser);
    } catch (error) {
      console.error('Error fetching quotes with user data:', error);
    } finally {
      setLoading(false); // Asegúrate de que loading se desactive siempre
    }
  };
  
    if (predefinedNames.length > 0) {
      fetchQuotesWithUser();
    }
  }, [page, predefinedNames]); // Elimina `assignedNames` de las dependencias

  useEffect(() => {
    if (quotes.length > 0 && predefinedNames.length > 0) {
      const userIds = quotes.map(quote => quote.author);
      fetchUserData(userIds).then(updatedUserCache => {
        const quotesWithUser = quotes.map((quote, index) => {
          const user = updatedUserCache[quote.author] || { twitter_username: null, profile_image: wallet, id: index };
          if (!user.twitter_username) {
            if (!assignedNames[user.id]) {
              const randomNameData = getRandomNameAndImage(predefinedNames);
              assignedNames[user.id] = { name: randomNameData.name + user.id, imageUrl: randomNameData.imageUrl };
            }
            user.twitter_username = assignedNames[user.id].name;
            user.profile_image = assignedNames[user.id].imageUrl;
          }
          return { ...quote, user };
        });
        setQuotesWithUser(quotesWithUser);
      });
    }
  }, [quotes, userCache, predefinedNames]);

  return (
    <div className="main-container">
      {showWelcome ? (
        <div className={`welcome-container ${bounceOut ? 'bounce-out' : ''}`}>
          <button onClick={handleEnterClick}>
            <img id="hover08" draggable="false" src={isMobile ? "/intro-mobile.png" : "/intro.png"} alt="intro" className="intro-image" />
          </button>
        </div>
      ) : (
        <>
          <div className="text-carousel-wrapper">
            <h1 className="text-6xl font-bold text-white centered-title carousel-title">
              {isMobile ? 'My Fucking Cryptos' : 'My Fucking Cryptos: Your Crypto Stories Matter'}
            </h1>
  
            <Socials />
  
            <div className="text-carousel text-align-center carousel-subtitle">
              <h3 className="text-4xl font-bold text-white">
                If cryptocurrencies have messed you up, share it, If you have unspeakable secrets, share them!
              </h3>
            </div>
          </div>
  
          <div className="filter-container" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '0em', marginTop: '1.5em', marginBottom:'-1em' }}>
            <label htmlFor="sortOrder" className="filter-label" style={{ marginRight: '0.75em', color: 'black', fontSize:'1.3em' }}>Order by:</label>
            <select id="sortOrder" value={sortOrder} onChange={handleSortChange} style={{ padding: '0.25em', fontSize:'1.2em' }}>
              <option value="recent">Most Recent</option>
              <option value="oldest">Oldest</option>
              <option value="most_voted">Most Voted</option>
            </select>
            <img draggable="false" src={wojakinfo} alt="info" className="button-wojak-info" loading="lazy" onClick={() => setShowModalWojak(true)} />
          </div>
  
          <div className="mt-4 quote-list-container-main">
            {!loading && quotesWithUser.length > 0 && (
              quotesWithUser.map((quote, index) => {
                const AdComponent = (
                  <div key="ad" className="quote-card ad-component ad-quote-card">
                      <div className='ensens' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',fontWeight: 'bold', justifyContent:'center', marginTop:'1.5em' }}>
                            <img draggable="false" src={degenadprofile} alt="wallet" className="button-wallet-ens" loading="lazy" />
                            <span>Fucking Advertisement</span>
                      </div>                    
                    <img draggable="false" src={`${process.env.REACT_APP_API_URL}/ads/ad-base.png`} alt="Ad" style={{border:'3px solid black',marginTop:'0.5em'}}/>

                    <Link to="/advertisement">
                      <img draggable="false" src={degenadmoreinfo} alt="More Info AD" style={{ marginTop: '12px' }} />
                    </Link>

                  </div>                  
                );           
                     
  
                const hasVoted = votedQuotes[quote.id] && (
                  votedQuotes[quote.id].userId === walletAddress ||
                  votedQuotes[quote.id].userId === fingerprint ||
                  votedQuotes[quote.id].ipAddress === ipAddress
                );
                const voteType = hasVoted ? votedQuotes[quote.id].col : null;
                const showThanks = showThanksBox[quote.id];
  
                return (
                  <React.Fragment key={quote.id}>
                  <div key={quote.id} className={`quote-card ${determineBackgroundClass(quote.text)}`}>
                    <Link key={quote.id} to={`/${quote.url_slug}`} className="quote-link">
                      <div className='ensens' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '0.25em', paddingTop: '1.5em',fontWeight: 'bold', justifyContent:'center' }}>
                        {quote.user && quote.user.twitter_handle ? (
                          <>
                            <img draggable="false" src={quote.user.profile_image} alt="wallet" className="button-wallet-ens round-image" loading="lazy" />
                            <span>{quote.user.twitter_username}</span>
                          </>
                        ) : (
                          <>
                            <img draggable="false" src={quote.user.profile_image || wallet} alt="wallet" className="button-wallet-ens" loading="lazy" />
                            <span>{quote.user.twitter_username}</span>
                          </>
                        )}
                      </div>
  
                      {quote.imageUrl ? (
                        <div className="quote-image-background">
                          <img draggable="false" src={quote.imageUrl} alt="Quote" className="quote-image" loading="lazy" />
                        </div>
                      ) : (
                        <p className="quote-text" style={{ border: `3px solid black`, userSelect: 'none' }}>{quote.text}</p>
                      )}
                    </Link>
                    <div className="quote-footer">
                      {hasVoted && showThanks && (
                        <div className={`vote-thanks ${voteType === 'moon_votes' ? 'moon' : 'rekt'}`}>
                          <p>Thank you for your Vote! <span className='check-lucky' onClick={() => handleCheckClick(quote)} style={{ cursor: 'pointer'}}>Check if you got lucky 🤞</span></p>
                        </div>
                      )}
                      <div 
                        className="vote-option" 
                        onClick={() => !hasVoted && handleVote(quote.id, 'moon_votes')}
                        style={{ pointerEvents: hasVoted ? 'none' : 'auto' }}
                      >
                        <img className={`vote-option ${hasVoted ? 'voted' : ''} button-moon`} draggable="false" src={moon} alt="moon" style={{ opacity: hasVoted ? 0.25 : 1 }} />
                        <span className='votes-moon-counter'><AnimatedCounter target={quote.moon_votes || 0} /></span>
                      </div>
                      <div 
                        className="vote-option" 
                        onClick={() => !hasVoted && handleVote(quote.id, 'rekt_votes')}
                        style={{ pointerEvents: hasVoted ? 'none' : 'auto' }}
                      >
                        <img className={`vote-option ${hasVoted ? 'voted' : ''} button-rekt`} draggable="false" src={rekt} alt="rekt" style={{ opacity: hasVoted ? 0.25 : 1 }} />
                        <span className='votes-rekt-counter' style={{ opacity: 1 }}><AnimatedCounter target={quote.rekt_votes || 0} /></span>
                      </div>
                    </div>
                  </div>
                    {/* Renderiza el anuncio en la segunda posición */}
                    {index === 0 && enableAds && AdComponent}
                  </React.Fragment>
                );
              })
            )}
          </div>
  
          <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0em', marginBottom:'1em', alignItems:'center' }}>
            {page > 1 && (
              <>
                <button onClick={handlePreviousPage} disabled={page === 1} style={{ padding: '0.5em 1em' }}>
                  <img className="previous-button button-image" draggable="false" src={previous} alt="previous" />
                </button>
                <span style={{fontSize:'1.3em', letterSpacing:'-3px'}}>{page} / {totalPages}</span>
              </>
            )}
            <button onClick={handleNextPage} disabled={page >= totalPages} style={{padding: '0.5em 1em', opacity: page >= totalPages ? 0.5 : 1, pointerEvents: page >= totalPages ? 'none' : 'auto'}}>
              <img className="next-button button-image" draggable="false" src={next} alt="next" />
            </button>
          </div>
  
          <Partners />      
        </>
      )}
      <ModalFrens show={showModalFrens} onClose={handleCloseModalFrens}>
        {modalContent}
      </ModalFrens>
      <Modal show={showModalWojak} onClose={handleCloseModalWojak} />
    </div>
  );
  
};

export default memo(QuoteList);
